import React from "react";

export class GuestLayout extends React.Component {
    render() {
        return (
            <div className="page auth-page">
                <div className="container auth-page__container">
                    <div className="auth-page__panel panel auth-panel">
                        { this.props.children }
                    </div>
                </div>
            </div>
        );
    }
}