import React from "react";
import NavBar from "../components/layouts/NavBar";

export class AuthenticatedLayout extends React.Component {
    render() {
        return (
            <>
                <NavBar/>
                <div className="container-xxl mt-4 mb-4">
                    { this.props.children }
                </div>
            </>
        );
    }
}