import React from "react";
import {GuestLayout} from "../../layouts/GuestLayout";
import {SignInButton} from "../../components/SignInButton";

export class Login extends React.Component {
    render() {
        return(
            <GuestLayout>
                <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="form-Auth">
                    <h2 className="m-0">Authorization</h2>
                    <div>Enter your credentials to access your account</div>
                    <div>
                        <SignInButton/>
                    </div>
                </div>
                </div>
            </GuestLayout>
        );
    }
}