import {FormattedMessage, useIntl} from "react-intl";
import React, {useState} from "react";
import PropTypes from "prop-types";

function Search({onSearch, onChange, children}) {
    const intl = useIntl();
    const [search, setSearch] = useState('');

    return (
        <div className="input-group-project-st">
            <div className="form-outline" style={{flex: 'auto'}}>
                <div className="input-group">
                    <span className="input-group-text"><i className="fa fa-search color-sky-grey"></i></span>
                    <input
                        type="text"
                        name="search"
                        id="search"
                        placeholder={intl.formatMessage({id: 'search_placeholder'})} className="form-control br-l-none"
                        value={search}
                        onChange={(e) => {
                            onChange(e.target.value);
                            setSearch(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter') {
                                onChange(e.target.value);
                                setSearch(e.target.value);
                                onSearch(e.target.value);
                            }
                        }}
                    />
                </div>
            </div>
            <button type="button" className="btn btn-secondary" onClick={(e) => onSearch(search)}>
                <FormattedMessage id="search_button"/>
            </button>
            { children }
        </div>
    );
}

Search.propTypes = {onSearch: PropTypes.func};

export default Search