import BModal from 'react-bootstrap/Modal';
import Title from 'react-bootstrap/ModalTitle';
import Header from 'react-bootstrap/ModalHeader';
import Body from 'react-bootstrap/ModalBody';
import Footer from 'react-bootstrap/ModalFooter';

function Modal({show, title, footer, onClose, size, children}) {
    return (
        <BModal show={show} onHide={onClose} size={size}>
            <Header closeButton>
                <Title>{title !== undefined ? title : 'title' }</Title>
            </Header>

            <Body>
                {children}
            </Body>

            { footer !== undefined ? (
                <Footer>
                    { footer }
                </Footer>
            ) : null}
        </BModal>
    );
}

export default Modal;