export const msalConfig = {
    auth: {
      clientId: "46b4aede-a4a7-4486-8017-bccabc6c6509",
      authority: "https://login.microsoftonline.com/4a63f032-09ff-4f29-92db-7116a16db5c7", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://box.laer-gruppe.de",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Create scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    scopes: ["User.Read"]
  };
  
  // Create the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };
