class Status {
    static VACANT       = new Status('Vacant', 'lock_status_vacant');
    static OCCUPIED     = new Status('Occupied', 'lock_status_occupied');
    static OWN_USE      = new Status('OwnUse', 'lock_status_own_use');
    static MAINTENANCE  = new Status('Maintenance', 'lock_status_maintenance');
    static OUT_OF_ORDER = new Status('OutOfOrder', 'lock_status_out_of_order');
    static LOCKED       = new Status('Locked', 'lock_status_locked');
    static DELETED      = new Status('Deleted', 'lock_status_deleted');
    static DISPOSED     = new Status('Disposed', 'lock_status_disposed');
    static RESERVED     = new Status('Reserved', 'lock_status_reserved');

    /**
     * @param {string} name
     * @param {string} localizationKey
     */
    constructor(name, localizationKey) { this.name = name; this.localizationKey = localizationKey; }

    static fromApi(value) {
        switch (value) {
            case 'Vacant':      return this.VACANT;
            case 'Occupied':    return this.OCCUPIED;
            case 'OwnUse':      return this.OWN_USE;
            case 'Maintenance': return this.MAINTENANCE;
            case 'OutOfOrder':  return this.OUT_OF_ORDER;
            case 'Locked':      return this.LOCKED;
            case 'Deleted':     return this.DELETED;
            case 'Disposed':    return this.DISPOSED;
            case 'Reserved':    return this.RESERVED;
            default: throw new Error('Undefined lock status');
        }
    }

    static list() {
        return [Status.VACANT, Status.OCCUPIED, Status.OWN_USE, Status.MAINTENANCE, Status.LOCKED, Status.DELETED, Status.DISPOSED, Status.RESERVED];
    }
}

export default Status;