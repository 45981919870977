import React from "react";
import { useMsal } from "@azure/msal-react";
import {FormattedMessage} from "react-intl";
import {LightButton} from "./Buttons";

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/" // redirects the top level app after logout
      });
    }
  }

  return (
    <LightButton onClick={() => handleLogout("popup")}>
      <FormattedMessage id="logout"/>
    </LightButton>
  );
}
