import React, {useContext} from "react";
import {NavLink} from "react-router-dom";
// import {FormattedMessage} from "react-intl";
import {LOCALES} from "../../i18n/locales";
import {LangContext} from "../../App";
import {useMsal} from "@azure/msal-react";
import {SignOutButton} from "../SignOutButton";

function NavBar() {
    const { accounts} = useMsal();
    const name = (accounts[0] && accounts[0].name) || 'undefined';

    const languages = [
        { name: 'English', code: LOCALES.ENGLISH },
        { name: 'Русский', code: LOCALES.RUSSIAN },
        { name: 'Deutsch', code: LOCALES.GERMAN }
    ];
    const {locale, setLocale} = useContext(LangContext);

    const handleChangeLocale = ({target: {value}}) => {
        setLocale(value);
        localStorage.setItem('locale', value);
    };

    // const menu = [
    //     {link: '/users', label_key: 'users'},
    //     {link: '/locks', label_key: 'locks'},
    //     {link: '/customers', label_key: 'customers'},
    // ];

    return (
        <nav className="py-3 navbar navbar-dark bg-primary navbar-expand-lg auto-hiding-navbar">
            <div className="container">
                <NavLink className="navbar-brand" to="/">Laer Gruppe</NavLink>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {/*<div className="collapse navbar-collapse" id="navbarSupportedContent">*/}
                {/*    <ul className="navbar-nav ml-auto">*/}
                {/*        {menu.map(({link, label_key}, index) => (*/}
                {/*            <li className="nav-link" key={index}>*/}
                {/*                <NavLink className="nav-link" aria-current="page" to={link}><FormattedMessage id={label_key}/></NavLink>*/}
                {/*            </li>*/}
                {/*        ))}*/}
                {/*    </ul>*/}
                {/*</div>*/}
                <ul className="nav navbar-nav navbar-right">
                    <div className="d-flex align-items-center">
                        <span className="text-white"> { name } </span>
                    </div>
                    <div className="mx-2">
                        <select value={locale} onChange={handleChangeLocale} className="form-control">
                            {languages.map(({name, code}) => (
                                <option key={code} value={code}>{name}</option>
                            ))}
                        </select>
                    </div>
                    <SignOutButton />
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;