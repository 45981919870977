import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

class Button extends React.Component {
    render() {
        return (
            <button
                className={ this.props.className + (this.props.disabled ? ' disabled' : '') }
                type="button"
                onClick={ this.props.onClick }
                disabled={this.props.disabled}
            >{ this.props.children }</button>
        );
    }
}

Button.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

// Button.props.disabled = false;

const DefaultButton = styled(Button).attrs({className: 'btn btn-default'})``;
const PrimaryButton = styled(Button).attrs({className: 'btn btn-primary'})``;
const SecondaryButton = styled(Button).attrs({className: 'btn btn-secondary'})``;
const DangerButton = styled(Button).attrs({className: 'btn btn-danger'})``;
const LightButton = styled(Button).attrs({className: 'btn btn-light'})``;

export {Button, DefaultButton, PrimaryButton, SecondaryButton, DangerButton, LightButton};