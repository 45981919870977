
class UserBankRating {
    /** @type {string} */
    userId;
    /** @type {string} */
    userName;
    /** @type {string} */
    performedUserId;
    /** @type {string} */
    performedUserName;
    /** @type {Number} */
    bankRating;
    /** @type {Date} */
    updateDate;

    constructor(data: Object|null = null) {
        if(data !== undefined && data !== null) {
            this.userId = data.userId;
            this.userName = data?.userName;
            this.performedUserName = data?.performedUserName;
            this.performedUserEmail = data?.performedUserEmail;
            this.bankRating = data?.bankRating;
            this.updateDate = new Date(data?.updatedAt + " Z");
        }

        else {
            this.userId             = '';
            this.userName           = '';
            this.performedUserId    = '';
            this.performedUserName  = '';
            this.bankRating         = -1;
            this.updateDate         = Date.now();
        }
    }
}

export default UserBankRating;