class UserHasAccessInfo {
    /** @type {boolean} */
    lockStatusOk;
    /** @type {string} */
    lockStatusText;

    /** @type {boolean} */
    userBankStatusOk;
    /** @type {string} */
    userBankStatusText;

    /** @type {boolean} */
    manualOk;

    constructor(data: Object|null = null) {
        if(data !== undefined && data !== null) {
            this.lockStatusOk       = data.lockStatusOk;
            this.lockStatusText     = data.lockStatusText;
            this.userBankStatusOk   = data.userBankStatusOk;
            this.userBankStatusText = data.userBankStatusText;
            this.manualOk           = data.manualOk;
        }

        else {
            this.lockStatusOk       = false;
            this.lockStatusText     = '';
            this.userBankStatusOk   = false;
            this.userBankStatusText = '';
            this.manualOk           = false;
        }
    }
}

export default UserHasAccessInfo;