class FileUpload {
    /** @type {string} */
    id;
    /** @type {string} */
    fileName;
    /** @type {Date} */
    dateTime;
    syncTaskSatus;
    errorMessage;

    constructor(data) {
        if (data !== undefined && data !== null) {
            this.id = data.id;
            this.fileName = data.fileName;
            this.dateTime = new Date(data?.dateTime + "Z");
            this.syncTaskSatus = data.syncTaskSatus;
            this.errorMessage = data.errorMessage;
        }

        else {
            this.id = data.id;
            this.fileName = data.fileName;
            this.dateTime = Date.now();
            this.syncTaskSatus = data.errorMessage;
            this.errorMessage = data.errorMessage;
        }
    }
}

export default FileUpload;